import router from '@/router';
import jwtToken from '@/plugins/jwt/jwt-token.js';
import user from '@/plugins/jwt/user.js';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';

let endpoint;

if (process.env.NODE_ENV === 'production') {
  endpoint = {
    baseUrl: "https://api.jurisleg.com/",
    metabrokerUrl: "https://metadev.infutura.es/"
  };
} else {
  endpoint = {
    baseUrl: "http://localhost:8000/",
    metabrokerUrl: "https://metadev.infutura.es/"
  };
}

// endpoint = {
//   baseUrl: "http://localhost:8000/",
//   metabrokerUrl: "https://metadev.infutura.es/"
// };

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

let showToastAuthorization = true;

axios.defaults.baseURL = endpoint.baseUrl;

axios.interceptors.request.use(
  (config) => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    if (jwtToken.getToken()) {
      config.headers['Authorization'] = 'Bearer ' + jwtToken.getToken();
      config.headers['UnitId'] = user.getUnit();
    }
    config.baseURL = determineBaseURL(config.url);
    
    const isMetabrokerURL = config.url.includes('/metabroker');

    if (isMetabrokerURL) {
      config.url = config.url.replace('/metabroker', '');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (
      response.data.status == 'Token has Expired' ||
      response.data.status == 'Token not provided' ||
      response.data.status == 'Token is Invalid' ||
      response.data.status == 'Authorization Token not found'
    ) {
      jwtToken.removeToken();
      router.push({ path: '/pages/login' });
    }
    return response;
  },
  (error) => {
    console.log(error);

    let errorResponseData = error?.response?.data;

    const errors = [
      'token_invalid',
      'Token not provided',
      'Token has expired',
      'Token is invalid',
      'token_expired',
      'token_not_provided',
      'Expired JWT Token',
      'JWT Token not found',
      'Token is Expired',
    ];


    if (errors.includes(errorResponseData?.message)) {
      jwtToken.removeToken();
      router.push({ path: '/pages/login' });
    }

    if (error?.response?.status == 422) {
      let html = '';

      for (let key in error['response']['data']['errors']) {
        for (let message in error['response']['data']['errors'][key]) {
          html += error['response']['data']['errors'][key][message] + '<br>';
        }
      }

      Toast.fire({
        icon: 'error',
        title: 'Error en el formulario',
        html: html,
      });
    }

    if (error?.response?.status == 500) {
      if(error.config.url != "v1/customers/find_by_dni"){
        Toast.fire({
          icon: 'error',
          title: 'Error en el formulario',
          html: error['response']['data']['message'] + ' <br> Linea ' + error['response']['data']['line'],
        });
      }
    }

    if (error?.response?.status == 401) {
      if (showToastAuthorization) {
        showToastAuthorization = false;
        Toast.fire({
          icon: 'error',
          title: 'Sesión expirada',
          html: 'Vuelva a iniciar Sesión',
        });
        router.push({ path: '/pages/login' });
        setTimeout(() => {
          showToastAuthorization = true;
        }, 4000);
      }
    }

    return Promise.reject(error);
  }
);

function determineBaseURL(url) {
  if (url.startsWith('/metabroker')) {
    return endpoint.metabrokerUrl;
  } else {
    return endpoint.baseUrl;
  }
}
