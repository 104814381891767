<template>
  <div>
    <CModal 
      :visible="ReminderModal" 
      :backdrop="'static'"
      @close="() => { ReminderModal = false }"
      class="responsive-modal"
    >   
      <CModalHeader class="custom-header">
        <CModalTitle class="custom-title">Recordatorios!</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <template v-for="reminder in reminders" :key="reminder">
          <CCard class="responsive-card">
            <CCardHeader>
              <b>{{ reminder.description }}</b>
            </CCardHeader>
            <CCardBody>
              <p>{{ 'Nos complace recordarle que la fecha '+reminder.date_when+' tiene un evento pendiente.' }}</p>
              <p>{{ 'Descripcion: ' + reminder.description }}</p>
              <p v-if="reminder.customer != null">
                {{ 'Cliente: ' + reminder.customer.firstname + ' ' + reminder.customer.lastname }}
              </p>
            </CCardBody>
          </CCard>
        </template>   
      </CModalBody>
    </CModal>

    <CModal 
      :visible="RevisionModal" 
      size="lg" 
      :backdrop="'static'"
      @close="() => { RevisionModal = false }"
      class="responsive-modal"
    >
      <CModalHeader class="custom-header">
        <CModalTitle class="custom-title">Recordatorios!</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CCard class="responsive-card">
          <CCardHeader>
            <b>Lista de clientes sin interacción</b>
          </CCardHeader>
          <CCardBody>
            <div class="table-responsive">
              <VTable 
                class="table table-striped responsive-table"
                :data="items"  
                :page-size="10"
                v-model:currentPage="currentPage"
                @totalPagesChanged="totalPages = $event"
                :filters="filters"
              >
                <template #head>
                  <tr>
                    <th v-for="field in fields" :key="field" :class="field._classes">
                      {{ field.label }}
                    </th>
                  </tr>
                </template>
                <template #body="{ rows }">
                  <tr v-if="rows.length == 0">
                    <td :colspan="fields.length">
                      <center>
                        <h4 style="margin: 0;">Sin registros</h4>
                      </center>
                    </td>
                  </tr>
                  <tr v-for="item in rows" :key="item">
                    <td @click="RevisionModal = false">
                      <a :href="'#/customers/'+item.id+'/edit'">{{ item.firstname + ' ' + item.lastname }}</a>
                    </td> 
                    <td>{{ item.email }}</td> 
                    <td>{{ item.revision_time + ' días' }}</td> 
                    <td>{{ item.days_difference + ' días' }}</td> 
                  </tr>
                </template>
              </VTable>
              <VTPagination
                v-model:currentPage="currentPage"
                :total-pages="totalPages"
                :boundary-links="true"
                :max-page-links="5"
                class="pagination-responsive"
              />
            </div>
          </CCardBody>
        </CCard> 
      </CModalBody>  
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TheReminder',
  data() {
    return {
      reminders: null,
      items: [],
      ReminderModal: false,
      RevisionModal: false,
      totalPages: 1,
      currentPage: 1,
      paginate: ['items'],
      fields: [
        { key: "name", label: "Nombre y Apellido", _classes: "text-center" },
        { key: "email", label: "Correo Electrónico", _classes: "text-center" },
        { key: "revision_time", label: "Tiempo de Revisión", _classes: "text-center" },
        { key: "days_difference", label: "Días sin interacción", _classes: "text-center" },
      ],
    }
  },
  mounted(){
    this.getMyReminders();
    this.getRevision();
  },
  methods:{
    getMyReminders(){
      axios.get('reminders/todays_reminder')
      .then(resp => {
        this.reminders = resp.data
        if(this.reminders.length != 0){
          this.ReminderModal = true;
        }
      })
      .catch(err => {
        console.log(err)
      });
    },
    getRevision(){
      axios.get('v1/customers/revision/1')
      .then(resp => {
        this.items = resp.data
        if(this.items.length != 0){
          this.RevisionModal = true;
        }
      })
      .catch(err => {
        console.log(err)
      });
    },
  }
}
</script>

<style scoped>
  /* Estilo para el encabezado */
  .custom-header {
    background-color: #005068;
    color: white !important;
  }
  .custom-title {
    color: white;
  }

  /* Estilo para el modal responsive */
  .responsive-modal {
    max-width: 100%;
    width: 90vw;
    max-height: 90vh;
    overflow-y: auto;
  }

  /* Estilo para las tarjetas y tablas en dispositivos móviles */
  .responsive-card {
    margin-bottom: 1rem;
  }

  .table-responsive {
    overflow-x: auto;
  }

  .responsive-table {
    font-size: 0.9em; /* Reduce el tamaño de la fuente para pantallas pequeñas */
  }

  .pagination-responsive {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .btn-close {
    color: white !important;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>
