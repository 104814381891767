import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const UserPage = () => import('@/views/users/UserPage')
const UserCreate = () => import('@/views/users/UserCreate')
const UserEdit = () => import('@/views/users/UserEdit')

const ClientPage = () => import('@/views/Client/ClientPage')
const ClientEdit = () => import('@/views/Client/ClientEdit')
const ClientChange = () => import('@/views/Client/ClientChange')

const TaskPage1 = () => import('@/views/Task/TaskPage')
const ReminderPage1 = () => import('@/views/Reminder/ReminderPage')
const OpportunitiesPage = () => import('@/views/Opportunities/OpportunitiesPage')
const RevisionPage1 = () => import('@/views/Revision/RevisionPage')

const TaskPage = () => import('@/views/tasks/TaskPage')
const TaskCreate = () => import('@/views/tasks/TaskCreate')
const TaskEdit = () => import('@/views/tasks/TaskEdit')

const NoteCreate = () => import('@/views/tasks/notes/NoteCreate')

const ProspectPage = () => import('@/views/prospects/ProspectPage')

const ReminderPage = () => import('@/views/reminders/ReminderPage')
const ReminderCreate = () => import('@/views/reminders/ReminderCreate')
const ReminderEdit = () => import('@/views/reminders/ReminderEdit')
const ReminderCustomerCreate = () => import('@/views/reminders/ReminderCustomerCreate')

const RevisionPage = () => import('@/views/revisions/RevisionPage')

const ProductTypeCreate = () => import('@/views/product_types/ProductTypeCreate')
const ProductTypeEdit = () => import('@/views/product_types/ProductTypeEdit')
const ProductTypePage = () => import('@/views/product_types/ProductTypePage')

const FileAreaCreate = () => import('@/views/customers/files_area/FileAreaCreate')

const CarteraEbrokerCreate = () => import('@/views/cartera_ebroker/CarteraEbrokerCreate')
const CarteraEbrokerEdit = () => import('@/views/cartera_ebroker/CarteraEbrokerEdit')
const CarteraEbrokerPage = () => import('@/views/cartera_ebroker/CarteraEbrokerPage')

const TimelinePage = () => import('@/views/timelines/TimelinePage')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: 'timeline',
        name: 'Timeline',
        component: TimelinePage
      },
      {
        path: 'prospects',
        name: 'Propectos',
        component: ProspectPage,
      },
      {
        path: 'customers',
        name: 'Clientes',
        component: {
        render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Clientes',
            meta: { label: 'Clientes' },
            component: ClientPage
          },  
          {
            path: ':id/edit',
            name: 'Editar Cliente',
            meta: { label: 'Clientes' },
            component: ClientEdit
          },
          {
            path: ':id/change',
            name: 'Cambio Cliente',
            meta: { label: 'Clientes' },
            component: ClientChange
          }
        ],
      },
      {
        path: 'task1',
        name: 'Task1',
        redirect: 'task1',
        component: {
        render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: '',
            meta: { label: 'Task1' },
            component: TaskPage1
          }
        ]
      },

      {
        path: 'reminder1',
        name: 'reminder1',
        redirect: 'reminder1',
        component: {
        render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: '',
            meta: { label: 'reminder1' },
            component: ReminderPage1
          }
        ]
      },
      {
        path: 'opportunities',
        name: 'opportunities',
        redirect: 'opportunities',
        component: {
        render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: '',
            meta: { label: 'opportunities' },
            component: OpportunitiesPage
          }
        ]
      },

      {
        path: 'revision',
        name: 'revision',
        redirect: 'revision',
        component: {
        render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: '',
            meta: { label: 'revision' },
            component: RevisionPage1
          }
        ]
      },

      








































       {
          path: 'tasks',
          name: 'Tareas',
          redirect: 'tasks',
          component: {
            render() {
              return h(resolveComponent('router-view'))
            },
          },
          children: [
            {
              path: '',
              name: 'Tareas',
              meta: { label: 'Tareas' },
              component: TaskPage
            },
            {
              path: 'create',
              name: 'Crear Tarea',
              meta: { label: 'Crear Tarea' },
              component: TaskCreate
            },
            {
              path: ':id/edit',
              name: 'Editar Tarea',
              component: TaskEdit ,
              meta: { reuse: false },
            },
            {
              path: ':id/notes/create',
              meta: { label: 'Crear Nota' },
              name: 'Crear Nota',
              component: NoteCreate
            },
            {
              path: ':customerId/create',
              name: 'Crear Tarea con Cliente',
              meta: { label: 'Crear Tarea' },
              component: TaskCreate
            },
            // {
            //   path: ':id/tasks/create',
            //   meta: { label: 'Crear Producto' },
            //   name: 'tasks.create',
            //   component: ProductCreate
            // },
            // {
            //   path: ':customerId/tasks/:productId/edit',
            //   meta: { label: 'Editar Producto' },
            //   name: 'tasks.edit',
            //   component: ProductEdit
            // }
          ]
        },
        {
          path: 'reminders',
          redirect: 'reminders',
          name: 'Recordatorios',
          component: {
            render() {
              return h(resolveComponent('router-view'))
            },
          },
          children: [
            {
              path: '',
              name: 'Recordatorios',
              meta: { label: 'Recordatorios' },
              component: ReminderPage
            },
            {
              path: 'create',
              name: 'Crear Recordatorio',
              meta: { label: 'Crear Recordatorio' },
              component: ReminderCreate
            },
            {
              path: ':id/edit',
              meta: { label: 'Editar Recordatorio' },
              name: 'Editar Recordatorio',
              component: ReminderEdit
            },
            {
              path: 'create/:customerId/reminder',
              name: 'Crear Recordatorio con cliente',
              meta: { label: 'Crear Recordatorio' },
              component: ReminderCustomerCreate
            },
          ]
        },
        {
          path: 'revisions',
          redirect: 'revisions',
          name: 'Revisión',
          component: {
            render() {
              return h(resolveComponent('router-view'))
            },
          },
          children: [
            {
              path: '',
              name: 'Revisión',
              meta: { label: 'Revisión' },
              component: RevisionPage
            }
          ]
        }, 
        {
          path: 'files_area',
          redirect: 'files_area',
          component: {
            render() {
              return h(resolveComponent('router-view'))
            },
          },
          children: [
            {
              path: ':customerId/create',
              name: 'Crear Archivo',
              meta: { label: 'Crear Archivo' },
              component: FileAreaCreate
            }
          ]
        },
        {
          path: 'users',
          name: 'Usuarios',
          meta: { label: 'Usuarios' },
          component: {
            render() {
              return h(resolveComponent('router-view'))
            },
          },
          children: [
            {
              path: '',
              name: 'Usuarios',
              meta: { label: 'Usuarios' },
              component: UserPage
            },
            {
              path: 'create',
              name: 'Crear Usuario',
              meta: { label: 'Crear Usuario' },
              component: UserCreate
            },
            {
              path: ':id/edit',
              meta: { label: 'Editar Usuario' },
              name: 'Editar Usuario',
              component: UserEdit
            }
          ]
        },
        {
          path: 'product_types',
          name: 'Tipo de Producto',
          redirect: 'product_types',
          component: {
            render() {
              return h(resolveComponent('router-view'))
            },
          },
          children: [
            {
              path: '',
              name: 'Tipo de Producto',
              meta: { label: 'Tipo de Producto' },
              component: ProductTypePage
            },
          {
              path: 'create',
              name: 'product_types.create',
              meta: { label: 'Crear Tipo de Producto' },
              component: ProductTypeCreate
            }, 
            {
              path: ':id/edit',
              meta: { label: 'Editar Tipo de Producto' },
              name: 'Editar Tipo de Producto',
              component: ProductTypeEdit
            },
          ]
        },
        {
          path: 'cartera_ebroker',
          redirect: 'cartera_ebroker',
          component: { render(c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'cartera_ebroker.index',
              meta: { label: 'Cartera ebroker' },
              component: CarteraEbrokerPage
            },
            {
              path: 'create',
              name: 'cartera_ebroker.create',
              meta: { label: 'Crear Cartera ebroker' },
              component: CarteraEbrokerCreate
            },
            {
              path: ':id/edit',
              meta: { label: 'Editar Cartera ebroker' },
              name: 'cartera_ebroker.edit',
              component: CarteraEbrokerEdit
            },
          ]
        },
      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion.vue'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs.vue'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards.vue'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels.vue'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses.vue'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups.vue'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs.vue'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },
    ],
  },

  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
